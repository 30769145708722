@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap";

body {
  margin: 0;
}

.o-flexbody__body {
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.o-flexbody__body .page-head,
.o-flexbody__body .page-footer,
.o-flexbody__body .c-notifications-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.o-flexbody__page {
  position: relative;
  width: 100% !important;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
}

@media (min-width: 1536px) {
  .o-flexbody__page {
    max-width: 80% !important;
  }
}

@media (min-width: 1200px) {
  .o-flexbody__page {
    max-width: 75% !important;
  }
}

.page-footer {
  padding: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #424243;
  text-align: center;
}

.standard-value {
  -webkit-justify-content: center !important;
  justify-content: center !important;
}

.standard-value.good {
  background-color: #6cb454;
}

.standard-value.warning {
  background-color: #ffc344;
}

.standard-value.critic {
  background-color: #db490a;
}

.datagrid-container {
  overflow: unset !important;
}

/* Aura css popover */
.c-popover {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-popover:hover .c-popover__content,
.c-popover:focus-within .c-popover__content,
.c-popover .c-popover__target:focus + .c-popover__content {
  display: block;
  opacity: 1;
  visibility: visible;
  will-change: opacity;
}

.c-popover__target {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background: none;
}

.c-popover__target,
.c-popover__target:link,
.c-popover__target:visited,
.c-popover__target:hover,
.c-popover__target:active,
.c-popover__target:focus {
  color: #1788c3;
  text-decoration: none;
  cursor: pointer;
}

.c-popover__content {
  list-style: none;
  margin: 0;
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  width: auto;
  padding: 12px 24px;
  text-align: left;
  font-weight: 500;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 6px 26px -6px rgba(0, 0, 0, 0.3);
  display: none;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  z-index: 100;
}

.c-popover__content::before,
.c-popover__content::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  content: "";
  border-width: 0;
  border-style: solid;
  border-color: transparent;
}

.c-popover__content::before {
  z-index: 1;
}

.c-popover__content::after {
  z-index: 0;
}

.c-popover__content,
.c-popover__content > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 48em) {
  .c-popover__content {
    display: block;
    width: 354px;
    padding: 32px 32px;
  }
}

.c-popover--bottom .c-popover__content {
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.c-popover--bottom .c-popover__content::before,
.c-popover--bottom .c-popover__content::after {
  bottom: 100%;
  left: 50%;
}

.c-popover--bottom .c-popover__content::before {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.c-popover--bottom .c-popover__content::after {
  -webkit-transform: translate(-50%, -1px);
  -moz-transform: translate(-50%, -1px);
  -ms-transform: translate(-50%, -1px);
  transform: translate(-50%, -1px);
}

.c-popover--bottom:hover .c-popover__content,
.c-popover--bottom:focus-within .c-popover__content,
.c-popover--bottom .c-popover__target:focus + .c-popover__content {
  -webkit-transform: translate(-50%, 14px);
  -moz-transform: translate(-50%, 14px);
  -ms-transform: translate(-50%, 14px);
  transform: translate(-50%, 14px);
}

.c-popover--bottom .c-popover__content::before,
.c-popover--bottom-start .c-popover__content::before,
.c-popover--bottom-end .c-popover__content::before {
  border-right-width: 12px;
  border-bottom-width: 16px;
  border-left-width: 12px;
  border-bottom-color: #fff;
}

.c-popover--bottom .c-popover__content::after,
.c-popover--bottom-start .c-popover__content::after,
.c-popover--bottom-end .c-popover__content::after {
  border-right-width: 13px;
  border-bottom-width: 17px;
  border-left-width: 13px;
  border-bottom-color: #f2f2f2;
}

.c-popover--dark.c-popover--bottom .c-popover__content::before,
.c-popover--dark.c-popover--bottom-start .c-popover__content::before,
.c-popover--dark.c-popover--bottom-end .c-popover__content::before {
  border-bottom-color: #212935;
}

.c-popover--dark.c-popover--bottom .c-popover__content::after,
.c-popover--dark.c-popover--bottom-start .c-popover__content::after,
.c-popover--dark.c-popover--bottom-end .c-popover__content::after {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.c-alert {
  position: relative;
  display: table;
  width: 100%;
  margin-bottom: 24px;
  padding: 24px 36px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.15;
  font-weight: 500;
  border-spacing: 0;
  border-radius: 3px;
}

@media (min-width: 48em) {
  .c-alert {
    padding: 24px 96px;
  }
}

.c-alert--light {
  background-color: #f2f2f2;
}

.c-alert--light .c-alert__body,
.c-alert--light .c-alert__body > * {
  color: #787878;
}

.c-alert--light.show-icon {
  --aura-c-alert-info-icon-light: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='%23787878' d='M12 1.992c-5.527 0-10.008 4.481-10.008 10.008s4.481 10.008 10.008 10.008 10.008-4.481 10.008-10.008-4.481-10.008-10.008-10.008zM11.023 7.43c0.247-0.229 0.542-0.343 0.885-0.343s0.637 0.114 0.882 0.343 0.368 0.503 0.368 0.825-0.124 0.596-0.371 0.822c-0.248 0.226-0.541 0.339-0.879 0.339-0.343 0-0.638-0.113-0.885-0.339s-0.372-0.5-0.372-0.822 0.124-0.597 0.372-0.825zM14.085 16.913h-4.012v-0.463c0.11-0.008 0.218-0.019 0.324-0.032s0.197-0.034 0.273-0.063c0.135-0.051 0.23-0.124 0.286-0.219s0.083-0.221 0.083-0.378v-3.694c0-0.148-0.034-0.278-0.102-0.391s-0.152-0.202-0.254-0.27c-0.076-0.051-0.191-0.1-0.346-0.146s-0.295-0.076-0.422-0.089v-0.463l3.11-0.165 0.095 0.095v5.040c0 0.148 0.032 0.274 0.095 0.377s0.154 0.181 0.273 0.232c0.084 0.038 0.178 0.072 0.279 0.102s0.208 0.051 0.317 0.063v0.463z'/%3E%3C/svg%3E");
}

.c-alert--light.show-icon.is-info:before {
  border-right-color: var(--aura-c-alert-light-text-color, #787878);
  content: "";
  background-image: var(--aura-c-alert-info-icon-light);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 47.99em) {
  .c-alert.show-icon {
    padding-left: 96px;
  }
}

.c-alert.show-icon .c-alert__body {
  display: block;
  text-align: left;
}

.c-alert.show-icon::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 24px;
  width: 48px;
  height: 32px;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(255, 255, 255, 0.8);
  background-size: auto !important;
  background-position: 0 50%;
}

.c-alert.show-icon.c-alert--success:before {
  border-right-color: #fff;
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='%23FFFFFF' d='M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM10 17l-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42-9 9z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
}
/* TODO: find a better way to add sticky position to sidebar */
.RaSidebar-appBarCollapsed {
  margin-top: 0 !important;
  transition: none !important;
}
.label-top {
  transform: translateY(-5px);
}
.chart-label-trend {
  color: green;
}
.customBoxShadow {
  padding: 40px;
  border-radius: 28px;
  box-shadow: 0px 2.842px 46.326px 5.684px rgba(0, 0, 0, 0.07);
  .MuiBox-root {
    border-left: 1px solid #0c8de922;
    border-bottom: 1px solid #0c8de922;
    border-top: 1px solid #0c8de922;
    border-radius: 10px 0px 0px 10px;
  }
}
.landing-page-list > .RaList-main > .RaList-content {
  box-shadow: none;
  border: 1px solid #0c8de922;
  border-radius: 10px;
  width: 100%;
}
.landing-page-list.tabs > .RaList-main > .RaList-content {
  border-radius: 0px 10px 10px 0px;
}
